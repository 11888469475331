import { createSelector } from "@reduxjs/toolkit";
import { CourseResultDTO, PlanConfigurationDTO, TestResultDTO, TestTakerScheduleDTO } from "external-api/license-api";
import { CourseResults, ImplicitBiasResults } from "src/type/globals";
import { UserInfo, ImplicitBiasResultModel, CourseResultModel } from "../../../../external-api/user-api";
import { IUserState } from "./userSlice";

const _getUser = (state: { userStore: IUserState }) => state.userStore.user;
const _getAllImplicitBiasResult = (state: { userStore: IUserState }) => state.userStore.results.survey;
const _getAllCourseResult = (state: { userStore: IUserState }) => state.userStore.results.course;
const _isFreeUser = (state: { userStore: IUserState }) => state.userStore.isFreeUser;
const _getUserStore = (state: { userStore: IUserState }) => state.userStore;
const _getTestInfo = (state: { userStore: IUserState }) => state.userStore.testInfo;
export const getUserDetails = createSelector(
    [_getUserStore],
    (user: IUserState) => {
        return {
            isAnonymousUser: user.isAnonymousUser,
            scheduleId: user.scheduleId
        };
    }
)

export const getUser = createSelector(
    [_getUser],
    (user: UserInfo) => {
        return user;
    }
)
export const getAuth = createSelector(
    [_getUserStore],
    (user: IUserState) => {
        return user.isAuthenticated;
    }
)
export const getPreviousResults = createSelector(
    [_getAllImplicitBiasResult, _getAllCourseResult],
    (results: ImplicitBiasResults[], courseResults: CourseResults[]) => {
        return {
            implicitBiasResults: results,
            courseResults: courseResults
        }
    }
)

export const getAvailablePackages = createSelector(
    [_getTestInfo],
    (testSchedule: TestTakerScheduleDTO) => {
        return {
            testConfiguration: testSchedule?.testConfigurations ?? [],
            courseConfiguration: testSchedule?.courseConfigurations ?? []
        };
    }
)

export const getHistoricalResults = createSelector(
    [_getTestInfo],
    (testSchedule: TestTakerScheduleDTO) => {
        return {
            course: testSchedule?.courseResults ?? [],
            test: testSchedule?.testResults ?? []
        }
    }
)

export const isUserFree = createSelector(
    [_isFreeUser],
    (isFree: boolean) => {
        return isFree;
    }
)
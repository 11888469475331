import React, { Fragment } from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { PaginationHeader } from 'src/components/pagination';
import { StyledButton } from 'src/components/buttons';
import { ContentfulImplicitAssociationAssessment } from 'types/graphql-types';
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { CustomLayout } from 'src/components/layout';
import { IFooterProps, IHeaderProps, headerTypes } from 'src/components/layout/custom-layout';
import { ButtonType } from 'src/components/buttons/styled-button';
/**
 * Weight Bias Introduction page
 * This is the introduction page for the weight bias section 
 * 
*/


const useStyles = makeStyles(() => ({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      paddingBottom: "1rem",
    },
    rta: {
      marginBottom: "auto",
    },
    button: {
      marginTop: "1rem",
    },
}))

interface IProps {
  data: ContentfulImplicitAssociationAssessment;
  handleSkip: () => void;
  handleContinue: () => void;
  fullWidth?: boolean
}

const ImplicitBiasPracticePage: React.FC<IProps> = ({ handleSkip, handleContinue, data, fullWidth = true }) => {
    const classes = useStyles();

    const footerProps: IFooterProps = {
    }

    const headerProps: IHeaderProps = {
      type: headerTypes.logo
    }

    return (
        <CustomLayout
          headerProps={headerProps}
          footerProps={footerProps}
      >

        <div className={classes.container}>
          <div className={classes.rta}>
            {data?.practiceRoundRta && <ContentfulRichText {...data?.practiceRoundRta as ContentfulRichTextType} />}
          </div>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleContinue}
            fullWidth={fullWidth}
          >
            {data.continuePracticeLabel}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSkip}
            fullWidth={fullWidth}
          >
            {data.skipPracticeLabel}
          </Button>
        </div>
        </CustomLayout>
    )
}

export default ImplicitBiasPracticePage;
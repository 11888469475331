import React, { Fragment, useEffect, useState } from 'react';
import { ISection } from 'src/type/globals';
import { ImplicitBiasConverter } from 'src/utilities/implicit-bias-utils';
import { ContentfulImplicitAssociationAssessment } from 'types/graphql-types';
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { ImplicitBiasIntroductionPage, ImplicitBiasPracticePage, ImplicitBiasPracticeIsOverPage, ImplicitBiasSection } from '.';
import { ImplicitBiasInput } from '../../../../external-api/service-api';
import { graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
/**
 * Weight Bias Survey page
 * This is the weight bias step of the survey
 *  
 */

interface IProps {
  data: ContentfulImplicitAssociationAssessment
  handleNextSection: () => void
  handleImplicitBiasInput: (answer: ImplicitBiasInput) => void
  handleSkipPracticeSection: () => void
}

const ImplicitBiasSurvey: React.FC<IProps> = ({ data, handleNextSection, handleImplicitBiasInput, handleSkipPracticeSection }) => {
  const [sections] = useState<ISection[]>(ImplicitBiasConverter(data) as ISection[]);
  const [sectionStep, setSectionStep] = useState(0);
  const [showIntroduction, setIntroduction] = useState(true);
  const [showSkip, setShowSkip] = useState(true);
  const [showPracticeIsOver, setShowPracticeIsOver] = useState(false);
  //const [showPracticeIsOver, setShowPracticeIsOver] = useState(sectionStep === 1);

  const dispatch = useDispatch();

  function handleNext() {
    if (sectionStep === sections.length - 1) {
      handleNextSection();
    }
    else {
      const nextSectionStep = sectionStep + 1;
      setSectionStep(nextSectionStep);
      if (nextSectionStep === 1) setShowPracticeIsOver(true)
    }
  }

  // -- here is the new function. 
  function handleSkip() {
    handleSkipPracticeSection();
    setShowSkip(false);
    handleNext();
  }

  if (showIntroduction) {
    return (
      <ImplicitBiasIntroductionPage
        data={data}
        handleNextSurveyStep={() => setIntroduction(false)}
      />
    )
  }


  // Wireframe #5
  if (showSkip) {
    //TODO:
    // -- Create page for wireframe #5.  Can copy ImplicitBiasIntroductionPage for the most part. 
    // -- Render that page here. 
    return (
      <ImplicitBiasPracticePage
        data={data}
        handleSkip={() => handleSkip()}
        handleContinue={() => setShowSkip(false)}
      />
    )
  }

  // Wireframe #6
  if (showPracticeIsOver) {
    // TODO:
    // -- Create page for wireframe #7.  Can copy ImplicitBiasIntroductionPage for the most part. 
    // -- Render that page here. 
    return (
      <ImplicitBiasPracticeIsOverPage
        data={data}
        handleContinue={() => setShowPracticeIsOver(false)}
      />
    )
  }


  return (
    <ImplicitBiasSection
      data={data}
      onSectionFinish={handleNext}
      sectionConfig={sections[sectionStep]}
      sectionStep={sectionStep + 1}
      totalSections={sections.length}
      setAnswer={handleImplicitBiasInput}
    />
  )
}


export const implicitBiasAssessmentFragment = graphql`
fragment implicitBiasAssessmentFragment on ContentfulImplicitAssociationAssessment {
    id
    title
    __typename
    secondCategoryTutorialImage {
      id
      contentful_id
      file {
        url
        contentType
      }
      fixed {
        ...GatsbyContentfulFixed
      }
      fluid {
        ...GatsbyContentfulFluid
      }
    }
    firstCategoryTutorialImage {
      id
        contentful_id
        file {
          url
          contentType
        }
        fixed {
          ...GatsbyContentfulFixed
        }
        fluid {
          ...GatsbyContentfulFluid
        }
    }
    firstAssociationPair {
      id
      image {
        id
        file {
          url
          contentType
        }
      }
      list {
        id
        image {
          id
          file {
            url
            contentType
          }
        }
        text
        title
      }
      text
      title
    }
    secondAssociationPair {
      id
      image {
        id
        file {
          url
          contentType
        }
      }
      list {
        id
        image {
          id
          file {
            url
            contentType
          }
        }
        text
        title
      }
      text
      title
    }
    labelForNo
    labelForLow
    labelForMedium
    labelForHigh
    resultTextNoBias
    resultTextBiased
    practiceRoundRta{
      raw
    }
    skipPracticeLabel
    continuePracticeLabel
    practiceIsOverRta{
      raw
    }
    practiceIsOverContinueLabel
  }  
  `


export default ImplicitBiasSurvey;

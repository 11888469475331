import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { AllImplicitAssociationAssessmentQuery, ContentfulImplicitAssociationAssessment } from "types/graphql-types";
import { useStaticQuery, graphql } from 'gatsby';
import Mustache from 'mustache';
/**
 * Previous Results information Container
 * This is the container for displaying the information for previous results IFF a user is logged in 
 * 
 */

const useStyles = makeStyles(theme => ({
	resultContainer: {
		padding: theme.spacing(3),
		fontFamily: "Roboto",
		fontSize: "1.1875rem",
		lineHeight: 1.2,
		backgroundColor: "#F5F5F5",
	},
}))

export function iatScoreToText(iat: ContentfulImplicitAssociationAssessment, score: number, intensity: number) {
	const firstPairFirstGroupLabel = iat?.firstAssociationPair && iat.firstAssociationPair[0]?.resultLabel || ""
	const firstPairSecondGroupLabel = iat?.firstAssociationPair && iat?.firstAssociationPair[1]?.resultLabel || ""

	const secondPairFirstGroupLabel = iat?.secondAssociationPair && iat.secondAssociationPair[0]?.resultLabel || ""
	const secondPairSecondGroupLabel = iat?.secondAssociationPair && iat?.secondAssociationPair[1]?.resultLabel || ""

	let prefForGroup = undefined
	let prefAgainstGroup = undefined

	let firstPairFirstItem = undefined
	let secondPairFirstItem = undefined
	let firstPairSecondItem = undefined
	let secondPairSecondItem = undefined

	if (score > 0) {
		firstPairFirstItem = firstPairFirstGroupLabel;
		secondPairFirstItem = secondPairFirstGroupLabel;
		firstPairSecondItem = firstPairSecondGroupLabel;
		secondPairSecondItem = secondPairSecondGroupLabel;

		prefForGroup = firstPairFirstGroupLabel;
		prefAgainstGroup = firstPairSecondGroupLabel;
	}
	else {
		firstPairFirstItem = firstPairFirstGroupLabel;
		secondPairFirstItem = secondPairSecondGroupLabel;
		firstPairSecondItem = firstPairSecondGroupLabel;
		secondPairSecondItem = secondPairFirstGroupLabel;

		prefForGroup = secondPairFirstGroupLabel;
		prefAgainstGroup = secondPairSecondGroupLabel;
	}

	let intensityLabel = undefined
	switch (intensity) {
		case 0:
		case 1:
			intensityLabel = iat.labelForNo
			break;
		case 2:
			intensityLabel = iat.labelForLow
			break;
		case 3:
			intensityLabel = iat.labelForMedium
			break;
		case 4:
			intensityLabel = iat.labelForHigh
			break;
		default:
			intensityLabel = iat.labelForNo
			break;
	}

	const mustacheHash = {
		intensity: intensityLabel,
		pref_for_group: prefForGroup,
		pref_against_group: prefAgainstGroup,

		first_pair_first_item: firstPairFirstItem,
		second_pair_first_item: secondPairFirstItem,
		first_pair_second_item: firstPairSecondItem,
		second_pair_second_item: secondPairSecondItem
	};

	let resultsText = undefined
	if (intensity > 1)
		resultsText = Mustache.render(iat.resultTextBiased as string, mustacheHash)
	else
		resultsText = Mustache.render(iat.resultTextNoBias as string, mustacheHash)

	return {
		intensity: intensityLabel,
		prefForGroup: prefForGroup,
		prefAgainstGroup: prefAgainstGroup,
		resultsText: resultsText
	}
}

interface IProps {
	score: number,
	intensity: number,
	iatId: string
}

const ImplicitBiasResult = ({ score, intensity, iatId }: IProps) => {

	const classes = useStyles();

	const data = useStaticQuery<AllImplicitAssociationAssessmentQuery>(graphql`
        query AllImplicitAssociationAssessment {
            allContentfulImplicitAssociationAssessment {
            edges {
                node {
                id
                __typename
                title
                firstAssociationPair {
                    id
                    text
					resultLabel
                }
                secondAssociationPair {
                    id
                    text
					resultLabel
                }
                labelForNo
                labelForLow
                labelForMedium
                labelForHigh
                resultTextNoBias
                resultTextBiased
                }
            }
            }
        }
    `).allContentfulImplicitAssociationAssessment.edges.find((x) => x?.node?.id === iatId)?.node;

	if (data && data.__typename === "ContentfulImplicitAssociationAssessment") {
		const { resultsText } = iatScoreToText(data as unknown as ContentfulImplicitAssociationAssessment, score, intensity)
		return (
			<Box className={classes.resultContainer} fontWeight={500}>{resultsText}</Box>
		)
	}
	else {
		return <></>
	}
}

export default ImplicitBiasResult
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { graphql, useStaticQuery } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { saveCourseResultAction } from "src/state/features/lms/lmsSlice";
import { AllContentfulCoursesDataQuery } from "types/graphql-types";
import { RouteComponentProps } from '@reach/router';
import CourseModule from "./course-module";
import { getAvailablePackages, getUser } from "src/state/features/user/userSelector";

interface IProps extends RouteComponentProps {
    courseId?: string;
}

const CourseModuleStepper = ({ courseId }: IProps) => {
    const data = useStaticQuery<AllContentfulCoursesDataQuery>(graphql`
    query AllContentfulCoursesData {
        allContentfulCourse {
            edges {
                node {
                    id
                    contentful_id
                    title
                    slug
                    overview {
                        raw
                        references {
                            __typename
                            ... on ContentfulVideo {
                                ...videoFragment
                            }
                        }
                    }
                    courseModules {
                        title
                        slug
                        id
                        contentful_id
                        moduleContent {                
                            ... on ContentfulAssessments {
                            id
                            slug
                            title
                            }
                            ... on ContentfulLearningMaterial {
                            id
                            slug
                            title
                            }
                        }
                    }
                }
            }
        }
    }
`).allContentfulCourse.edges.find(x => x?.node?.id === courseId)?.node;

    const dispatch = useDispatch();
    const [moduleStep, setModuleStep] = useState(0);
    const userInfo = useSelector(getUser)
    const availablePackages = useSelector(getAvailablePackages);
    const courseModules = availablePackages.courseConfiguration.length > 0 ?
        data?.courseModules?.filter(
            module => availablePackages?.courseConfiguration?.find(
                course => course.id === data.contentful_id
            )?.courseModuleIds?.includes(module?.contentful_id as string)
        ) : data?.courseModules

    async function handleNextModule() {
        if (courseModules && moduleStep < courseModules.length - 1) {
            setModuleStep(moduleStep + 1);
        } else {
            await dispatch(saveCourseResultAction())
        }
    }

    return (
        <>
            {courseModules && courseModules[moduleStep] &&
                <CourseModule courseModuleId={courseModules[moduleStep]?.id!} handleNextModule={handleNextModule} />
            }
        </>
    );
}

export default CourseModuleStepper;
import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { AllContentfulSurveyModulesQuery, ContentfulImplicitAssociationAssessment, ContentfulLikertAssessment, ContentfulMultipleChoiceQuestion } from "types/graphql-types";
import { RouteComponentProps } from '@reach/router';
import { ImplicitBiasInput } from '../../../external-api/service-api';
import { IImplicitBiasRequest } from "src/type/globals";
import _ from "lodash";
import { submitImplicitBiasAction } from "src/state/features/survey/surveySlice";
import { useDispatch } from "react-redux";
import SurveyModule from "./survey-module";
import { generatePracticeResponses } from "src/utilities/constants";


export type SurveyAssessmentType =
    ({ __typename: 'ContentfulLikertAssessment' } & ContentfulLikertAssessment)
    | ({ __typename: 'ContentfulMultipleChoiceQuestion' } & ContentfulMultipleChoiceQuestion)
    | ({ __typename: 'ContentfulImplicitAssociationAssessment' } & ContentfulImplicitAssociationAssessment)

export type DateOfBirth = {
    year: number,
    month?: number,
    day?: number
}

interface IProps extends RouteComponentProps {
    surveyId?: string;
}

const SurveyModuleStepper = ({ surveyId }: IProps) => {
    const data = useStaticQuery<AllContentfulSurveyModulesQuery>(graphql`
    query AllContentfulSurveyModules {
        allContentfulSurvey {
            edges {
                node {
                    id
                    contentful_id
                    title
                    slug
                    surveyModules {
                        id
                    }
                }
            }
        }
    }   
`).allContentfulSurvey.edges.find(x => x?.node?.id === surveyId)?.node;

    const [surveyStep, setSurveyStep] = useState(0);

    const currentModule = data?.surveyModules && data?.surveyModules[surveyStep] ? data?.surveyModules[surveyStep] : null;

    const dispatch = useDispatch();
    const [submissionData, setSubmissionData] = useState<IImplicitBiasRequest>({
        surveyId: data?.contentful_id! || "",
        implicitBiasType: "",
        rawInputs: [],
    });

    async function handleNextModule() {
        if (data?.surveyModules && surveyStep < data?.surveyModules?.length - 1) {
            setSurveyStep(surveyStep + 1);
        } else {
            //submit
            await dispatch(submitImplicitBiasAction(submissionData));

        }
    }

    function handleImplicitBiasInput(answer: ImplicitBiasInput) {
        const updatedWeightBiasAnswer = [...submissionData.rawInputs, answer];
        setSubmissionData({ ...submissionData, rawInputs: updatedWeightBiasAnswer });
    }

    function handleSkipPracticeSection() {
        const responses = generatePracticeResponses();
        setSubmissionData({ ...submissionData, rawInputs: responses });
    }

    return (
        <SurveyModule
            surveyModuleId={currentModule?.id}
            handleNextStep={handleNextModule}
            handleImplicitBiasInput={handleImplicitBiasInput}
            handleSkipPracticeSection={handleSkipPracticeSection}
        />
    )
}

export default SurveyModuleStepper;
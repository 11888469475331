import React, { } from "react"
import { Router } from "@reach/router"
import Survey from "src/client-pages/survey/survey"
import Course from "src/client-pages/course/course"
import { Paths } from "src/utilities/constants"
import CourseResults from "src/client-pages/course/course-results"
import SurveyResults from "src/client-pages/survey/survey-results"
import { useSelector } from "react-redux"
import { getAuth, getUser } from "src/state/features/user/userSelector"
import { UserInfo } from "../../external-api/user-api"
import { navigate } from "gatsby"
import SurveyModuleStepper from "src/client-pages/survey/survey-module-stepper"
import CourseModuleStepper from "src/client-pages/course/course-module-stepper"
import SelectionPage from "src/client-pages/selection"

interface IProtectedRoute {
    component: any
    path: string
    authenticated: boolean
}

const ProtectedRoute = ({ component: Component, authenticated, ...rest }: IProtectedRoute) => {
    if (authenticated === false && location.pathname !== Paths.LOGIN) {
        navigate(Paths.HOME);
        return null
    }
    return <Component {...rest} />
};

const App = () => {
    const user = useSelector(getUser);
    const isAuth = useSelector(getAuth);

    return (
        <Router>
            <ProtectedRoute component={Survey} path={`${Paths.SURVEY}/:surveySlug`} authenticated={isAuth} />
            <ProtectedRoute component={SurveyModuleStepper} path={`${Paths.SURVEY}/:surveySlug/:surveyId`} authenticated={isAuth} />
            <ProtectedRoute component={SelectionPage} path={Paths.SELECTION} authenticated={isAuth} />
            <ProtectedRoute component={SurveyResults} path={Paths.SURVEY_RESULTS} authenticated={isAuth} />
            <ProtectedRoute component={Course} path={`${Paths.COURSE}/:courseSlug`} authenticated={isAuth} />
            <ProtectedRoute component={CourseModuleStepper} path={`${Paths.COURSE}/:courseSlug/:courseId`} authenticated={isAuth} />
            <ProtectedRoute component={CourseResults} path={`${Paths.COURSE_RESULTS}`} authenticated={isAuth} />
        </Router>
    )
}

export default App;

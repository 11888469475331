import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ContentfulRichText, { ContentfulRichTextType } from 'src/contentful/contentful-rich-text';

/**
 * Tutorial Container
 * Container for the tutorial section of the weight bias survey.
 * There is a tutorial for each "Section" of the weight bias survey
 * 
 */

const useStyles = makeStyles(theme => ({
    content: {
        padding: "2.5rem 3.125rem 0 3.125rem",
        textAlign: "center",
        marginTop: "40px"
    },

    primaryAnswer: {
        color: "#03A208"
    },
    secondaryAnswer: {
        color: "#042AC1"
    },
    errorMarker: {
        color: "red"
    }
}))

interface IProps {
    overview?: any 
}

const ImplicitBiasTutorial: React.FC<IProps> = ({ overview }) => {
    const classes = useStyles();
    return (
        <div className={classes.content}>
            {/* {overview &&
                <ContentfulRichText {...overview as unknown as ContentfulRichTextType} />
            } */}

            <Typography> <strong>Put your thumbs over the buttons.</strong> </Typography>
            <Typography paragraph>If you make a mistake, a red <strong className={classes.errorMarker}>X</strong> will appear. Touch the other side.</Typography>
        </div>
    )
}

export default ImplicitBiasTutorial;